/*====================
1. button Mixin css start
====================*/
@mixin btn-action {
  background: $brand-primary;
  border-radius: 16px;
  font-weight: bold;
  font-size: 14px;
  color: $brand-white;
  border: 0;
  transition: all 0.3s ease;
  &:hover {
    background-color: $primary_dark;
  }
  &:focus {
    background: $brand-primary;
  }
  .left-icon {
    margin-right: 10px;
  }
  .right-icon {
    margin-left: 10px;
  }
}

@mixin btn-disabled {
  @include btn-action;
  background: $primary_10;
  color: $primary_40;
  cursor: no-drop !important;
  &:hover,
  &:focus {
    background: $primary_10;
    color: $primary_40;
  }
}

@mixin btn-white {
  @include btn-action;
  background: $brand-white;
  font-weight: 500;
  color: $brand-black;
  &:hover {
    color: $brand-white;
  }
}

@mixin btn-light-gray {
  @include btn-action;
  background: $neutral_10;
  font-weight: 500;
  color: $brand-black;
}

@mixin btn-green-border {
  @include btn-action;
  background: $brand-white;
  border: 2px solid $brand-primary;
  color: $brand-primary;
  &:hover {
    background: $brand-primary;
    color: $brand-white;
  }
  &:focus {
    background-color: $primary_dark;
    color: $brand-white;
  }
  &.btn-green-border-disabled {
    border: 2px solid $primary_20;
    color: $primary_20;
    cursor: no-drop !important;

    &:hover,
    &:focus {
      border: 2px solid $primary_20;
      color: $primary_20;
    }
  }
}

@mixin btn-transparent {
  @include btn-action;
  background-color: $brand-transparent;
  color: $brand-secondary;
  &:hover,
  &:focus {
    background-color: $brand-transparent;
    color: $brand-secondary;
  }

  &.btn-transparent-disable {
    color: $secondary_40;

    &:hover,
    &:focus {
      color: $secondary_40;
    }
  }
}

@mixin btn-error {
  @include btn-action;
  background-color: $error;
  &:hover {
    background-color: $error_dark;
  }
  &:focus {
    background-color: $error;
  }
  &.btn-error-disable {
    background-color: $error_10;
    color: $neutral_20;
  }
}

/*====================
2. Switch Mixin css start
====================*/
@mixin action-switch-btn {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  min-width: 48px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: $brand-secondary;
        border-color: $brand-secondary;

        &:before {
          transform: translateX(22px);
          background-color: $brand-white;
        }
      }
    }
  }

  .slider {
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-white;
    transition: .4s;
    border: 2px solid $brand-primary;

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 2px;
      background-color: $brand-primary;
      transition: .4s;
    }
  }

}

@mixin action-switch-disable-btn {
  @include action-switch-btn;
  input {
    &:checked {
      + .slider {
        background-color: $toggle-disable;

        &:before {
          background-color: $neutral_20;
        }
      }
    }
  }
  .slider {
    background-color: $toggle-disable;
    border: 2px solid $neutral_20;

    &:before {
      background-color: $neutral_20;
    }
  }
}

@mixin action-switch-error-btn {
  @include action-switch-btn;
  input {
    &:checked {
      + .slider {
        background-color: rgba($error, 0.1);

        &:before {
          background-color: $error;
        }
      }
    }
  }
  .slider {
    background-color: rgba($error, 0.1);
    border: none;

    &:before {
      background-color: $error;
    }
  }
}

@mixin action-switch-green-btn {
  @include action-switch-btn;
  input {
    &:checked {
      + .slider {
        background-color: $brand-primary;
        border-color: $brand-primary;

        &:before {
          background-color: $brand-white;
        }
      }
    }
  }

  .slider {
    border: 2px solid $brand-secondary;

    &:before {
      background-color: $brand-secondary;
    }
  }
}


/*====================
3. Check Box css start
====================*/
@mixin action-check-box {
  position: relative;

  .styled-checkbox {
    position: absolute;
    opacity: 0;
    height: 26px;
    width: 26px;
    z-index: 2;
    cursor: pointer;

    & + label {
      position: relative;
      padding: 0;
    }

    & + label:before {
      position: absolute;
      content: '';
      display: inline-block;
      width: 26px;
      height: 26px;
      background: $brand-white;
      top: -9px;
      border-radius: 8px;
      border: 2px solid $brand-primary;
      transition: all 0.3s ease-in-out;
    }

    &:hover + label:before {
      background: $primary_10;
    }

    &:checked + label:before {
      background: $brand-primary;
    }


    &:disabled + label:before {
      box-shadow: none;
      background: $toggle-disable;
      border: 2px solid $neutral_20;
    }

    &:checked + label:after {
      position: absolute;
      content: '';
      left: 7px;
      top: -2px;
      background: $brand-white;
      width: 12px;
      height: 12px;
      border-radius: 4px;
    }

    &:disabled, &:checked + label:after {
      background: $neutral_20;
    }

  }
  &.custom-checkbox {
    min-height: 26px;
    margin-bottom: 5px;

    .styled-checkbox {
      + label {
        padding-left: 35px;

        &:before {
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &:checked {
        + label {
          &:after {
            left: 6.5px;
            top: 4.5px;
          }
        }
      }
    }
  }
}

@mixin action-error-check-box {
  @include action-check-box;
  .styled-checkbox {
    & + label:before {
      border: 2px solid $error;
      background: $error;
    }

    &:hover + label:before {
      background: $error;
    }

    &:checked + label:before {
      background: $error;
    }

    &:checked + label:after {
      background: $error;
    }
  }
}

@mixin action-radio-box {
  input[type='radio'] {
    min-height: 24px;
    min-width: 24px;
    max-height: 24px;
    max-width: 24px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid $brand-primary;
    background: $brand-white;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all .3s ease-in-out;
    position: relative;
    margin-top: 0;

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          background: $primary_10;
          border-color: $primary_10;
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $brand-white;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:checked {
      background: $brand-primary;
    }

    &:disabled {
      background: $toggle-disable;
      border: 2px solid $neutral_20;

      &:after {
        background: $neutral_40;
      }
    }

    &:not(.switch) {
      &:after {
        opacity: var(--o, 0);
      }

      &:checked {
        --o: 1;
      }
    }
  }
}

@mixin action-radio-error-box {
  @include action-radio-box;
  input[type='radio'] {
    border-color: $error;
    background: $error;

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          background: $error;
          border-color: $error;
        }
      }
    }

    &:after {
      background: $error;
    }

    &:checked {
      background: $error;
    }

    &:disabled {
      background: $error;
      border-color: $error;

      &:after {
        background: $error;
      }
    }
  }
}

@mixin action-radio-orange-box {
  @include action-radio-box;
  input[type='radio'] {

    &:checked {
      background: $brand-secondary;
      border-color: $brand-secondary;
    }
  }
}


/*====================
4. Forms Mixin css start
====================*/

@mixin form-group {
  .search-icon {
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  label {
    color: $neutral_60;
  }
  .form-control {
    background-color: $toggle-disable;
    border-radius: 16px;
    border: 1px solid $neutral_20;
    color: $brand_black;
    min-height: 48px;

    &::placeholder {
      color: $neutral_40;
    }
  }
  .input-icon {
    position: absolute;
    top: 50%;
    right: 4%;
    transform: translateY(-50%);
    z-index: 1;
  }
}

@mixin form-group-error {
  label {
    color: $error;
  }
  .form-control {
    background-color: $error_10;
    border-radius: 16px;
    border: 1px solid $error;

    &::placeholder {
      color: $error;
    }
  }
}

@mixin form-group-high-light {
  label {
    color: $brand-primary;
  }
  .form-control {
    background-color: $toggle-disable;
    border-radius: 16px;
    border: 1px solid $brand-primary;

    &::placeholder {
      color: $brand-black;
    }
  }
}

@mixin form-group-typing {
  label {
    color: $brand-primary;
  }
  .form-control {
    background-color: $brand-white;
    border-radius: 16px;
    border: 1px solid $neutral_80;
  }
}

@mixin form-group-disable {
  label {
    color: $neutral_60;
  }
  .form-control {
    background-color: $neutral_20;
    border-radius: 16px;
    border: 1px solid $neutral_10;

    &::placeholder {
      color: $neutral_40;
    }
  }
}

@mixin form-group-filled {
  label {
    color: $neutral_40;
  }
  .form-control {
    background-color: $toggle-disable;
    border-radius: 16px;
    border: 1px solid $neutral_20;
  }
}
