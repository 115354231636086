/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/scss/bootstrap";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~intl-tel-input/build/css/intlTelInput.min.css";
@import "~ngx-toastr/toastr";
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
@import "spectrum.min.css";
@import "global_mixin";
@import "global_variables";

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


body {
  font-family: $dmsans;

  a {
    text-decoration: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

$font-sizes: 64, 48, 32, 24, 22, 18, 16, 14, 12, 10, 8;

@each $font-size in $font-sizes {
  .fs-#{$font-size} {
    font-size: #{$font-size}px;
  }
}


/*====================
1. button Mixin css start
====================*/

.btn-action {
  @include btn-action;
  width: 100%;
  height: 48px;
}

.btn-disabled {
  @include btn-disabled;
  width: 100%;
  height: 48px;
}

.btn-white {
  @include btn-white;
}

.btn-light-gray {
  @include btn-light-gray;
}

.btn-green-border {
  @include btn-green-border;
  width: 100%;
  height: 48px;
  line-height: 1;
}

.btn-transparent {
  @include btn-transparent;
}

.btn-error {
  @include btn-error;
}

/*====================
2. Switch css start
====================*/

.action-switch-btn {
  @include action-switch-btn
}

.action-switch-disable-btn {
  @include action-switch-disable-btn
}

.action-switch-error-btn {
  @include action-switch-error-btn
}

.action-switch-green-btn {
  @include action-switch-green-btn;
}

/*====================
3. check Box css start
====================*/

.action-check-box {
  @include action-check-box;
}

.action-error-check-box {
  @include action-error-check-box;
}

.action-radio-box {
  @include action-radio-box
}

.action-radio-error-box {
  @include action-radio-error-box;
}

.action-radio-orange-box {
  @include action-radio-orange-box
}


/*====================
4. Forms css start
====================*/
.form-group {
  @include form-group;
  margin-bottom: 1rem;
}

.form-group-error {
  @include form-group-error;
}

.form-group-high-light {
  @include form-group-high-light;
}

.form-group-typing {
  @include form-group-typing;
}

.form-group-disable {
  @include form-group-disable;
}

.form-group-filled {
  @include form-group-filled
}

.primary-color {
  color: $secondary_20;
}


.h-100vh {
  height: 100vh;
}

.br-16 {
  border-radius: 16px;
}

// Global padding
.pt-176 {
  padding-top: 176px;
}

.pt-108 {
  padding-top: 108px;
}

.pb-88 {
  padding-bottom: 88px;
}

.pb-82 {
  padding-bottom: 82px;
}

.py-82 {
  padding-bottom: 82px;
  padding-top: 82px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-50,
.py-50 {
  padding-bottom: 50px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pt-72,
.py-72 {
  padding-top: 72px;
}

.pt-76 {
  padding-top: 76px;
}

.pt-56 {
  padding-top: 56px;
}

.pt-40 {
  padding-top: 40px;
}

.p-32 {
  padding: 32px;
}

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.p-14 {
  padding: 14px;
}

.pb-14 {
  padding-bottom: 14px;
}

.pt-18 {
  padding-top: 18px;
}

.pt-32 {
  padding-top: 32px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.mb-0 {
  margin-bottom: 0;
}

.bg-brand-color {
  color: $brand-primary;
}

.bg-brand-primary {
  background-color: $brand-primary;
}

/*====================
Toast Component Css
====================*/
.toast-container {
  width: 100%;
  padding-left: 30px;

  .custom-toast {
    width: 100% !important;
    padding: 15px !important;
    border-radius: 16px !important;

    .row {
      align-items: center !important;

      .col-3 {
        top: 5px !important;
      }
    }

    &.toast-success {
      background-image: none !important;
      background-color: $success !important;
    }

    &.toast-warning {
      background-image: none !important;
      background-color: $warning !important;
    }

    &.toast-error {
      background-image: none !important;
      background-color: $error !important;
    }

    .toast-close-button {
      right: 0;
      top: 0;
    }
  }
}

/*====================
Common Css
====================*/
.auth-component {
  position: relative;
  height: 100%;
  min-height: 100vh;
  display: flex;
  background-color: $secondary_20;

  .col-xl-4 {
    max-width: 420px;
    flex: 1;
    z-index: 9;
  }


  .bg-right--img {
    position: absolute;
    top: 0;
    height: 100%;
    min-height: 100vh;
    right: 0;
    z-index: 0;
  }

  .action--form {
    .register-subtitle {
      color: $neutral_80;
    }

    .form-group {
      label {
        color: $neutral_60;
      }

      .eye-cond {
        position: absolute;
        top: 50%;
        right: 4%;
        transform: translatey(-50%);
      }

      .form-control {
        height: unset;
        border: none;
        padding: 5px 15px;

        &::placeholder {
          color: $neutral_40;
        }

        &.form-select {
          padding: 14px 15px;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .select-arrow {
        width: 15px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }

      input,
      input::-webkit-input-placeholder {
        font-size: 12px;
        line-height: 3;
      }
    }

    .otp-fields {
      margin-top: 40px;

      .form-group {
        padding: 0 20px;
        margin-bottom: 0;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .info-text {
      color: $neutral_60;
    }

    .terms-of-use {
      color: $primary_90;

      span {
        &.try-again {
          color: $secondary_60;
        }

        color: $brand-secondary;
      }

      a {
        color: $brand-secondary;
      }
    }

    .sign-in {
      .sign-in-link {
        color: $neutral_80;

        a {
          color: $brand-secondary;
        }
      }
    }
  }
}

.page-container {
  padding-left: 250px;
  padding-top: 80px;
  padding-right: 15px;

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.3);
  z-index: 1070;
}

.color-container {
  width: 350px;
  background: $brand-transparent;
  border: 0;

  .sp-picker-container {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;

    .sp-top {
      margin-bottom: 0;
      margin-top: 60px;

      .sp-top-inner {
        .sp-hue {
          border: 0;
        }

        .sp-color {
          border: 0;

          .sp-sat {
            .sp-val {
              .sp-dragger {
                border-radius: 50%;
                height: 10px;
                width: 10px;
                border: 4px solid #fff;
              }
            }
          }
        }
      }
    }

    .sp-initial {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;

      .sp-cf {
        span {
          width: 48px;
          height: 48px;
          border-radius: 8px;
        }

        .sp-thumb-el {
          &:first-child {
            display: none;
          }
        }
      }
    }

    .sp-input-container {
      width: 100%;

      .sp-input {
        text-transform: uppercase;
        height: 48px;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        background-clip: padding-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background-color: $toggle-disable;
        border-radius: 16px;
        border: 1px solid $neutral_20;
        color: $brand_black;
      }
    }
  }
}

.custom-model {
  max-width: 600px;

  .modal-content {
    border-radius: 16px;
    border: 0;
  }

  &.color-class {
    .modal-content {
      padding: 50px 60px;
    }

    .model-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-close {
        background: none;
        height: auto;
        width: auto;
      }
    }

    .modal-footer {
      border: 0;
      padding: 0;
    }
  }
  &.return-order-pickup {
    max-width: 500px;
    .modal-content {
      .modal-header {
        .btn-close {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.action-model {
  //height: 100vh;
  display: flex;
  align-items: center;

  .modal-content {
    border: 0;
    border-radius: 16px;

    .modal-header {
      border: 0;

      .modal-title {
        width: 100%;
      }
    }

    .modal-body {
      padding: 0 100px 40px;

      .action-view {
        background-color: $toggle-disable;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .img-wrapper {
          img {
            width: 18px;
            margin-right: 20px;
            color: $neutral_40;
          }
        }

        h6 {
          font-size: 16px;
          font-weight: bold;
          color: $brand-black;
        }
      }

      .btn-transparent {
        color: $neutral_40;
        font-weight: 500;
      }
    }
  }
}

.custom-popover.increase-width {
  width: 530px !important;
  max-width: 530px !important;
}

.custom-popover {
  border-radius: 16px;
  width: 280px;
  box-shadow: 0 0 32px rgba($brand-black, 0.1);

  &.no-arrow {
    .arrow {
      display: none;
    }
  }

  .arrow {
    &:after {
      border-bottom-color: $toggle-disable;
    }
  }

  &.no-last-border {
    .popover-body {
      .item {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .popover-body {
    padding: 1.5rem 1rem 0.5rem;

    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 15px 10px 15px;
      border-bottom: 1px solid $neutral_20;

      i {
        font-size: 20px;
      }

      &:first-child {
        padding: 0 10px 15px;
      }

      span {
        font-size: 16px;
        font-weight: bold;
        color: $brand_black;
      }

      p {
        font-size: 14px;
        color: $neutral_40;
      }

      .icon {
        width: 18px;
        margin-right: 20px;
        color: $neutral_40;
      }

      .remove-icon {
        margin-right: 0.5rem;
        width: 16px;
      }
    }
  }

  &.wishlist {
    overflow: hidden;

    .popover-header {
      padding: 20px;
      font-size: 18px;
      font-weight: bold;
      background-color: $neutral_60;
      color: $brand-white;
    }
  }

  @media (max-width: 576px) {
    position: fixed;
    bottom: 0;
    top: unset !important;
    width: 100%;
    border-radius: 16px 16px 0 0;
    transform: unset !important;
    max-width: unset;
    .popover-body {
      .item {
        padding: 15px 25px;
        background-color: $toggle-disable;
        border: 0;
        border-radius: 8px;
        margin-bottom: 5px;

        &:first-child {
          padding: 15px 25px;
        }

        &:last-child {
          background-color: $brand-transparent;
          padding: 15px;
          margin: 15px 0;
        }
      }
    }
  }
}

.custom-tooltip {
  .arrow {
    &:before {
      border-right-color: $brand-primary
    }
  }

  .tooltip-inner {
    background-color: $brand-primary;
    color: $brand-white
  }

  &.return-policy-tooltip {
    min-width: 250px;

    .arrow {
      &:before {
        border-top-color: $brand-primary;
        border-right-color: $brand-transparent;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.seller-setup {
  .seller-tab-details {
    height: 100vh;
    display: flex;
    padding-top: 35px;

    .row {
      height: 100%;
    }

    .seller-tab-left-box {
      padding: 80px 40px;
      background-color: $secondary_20;
      height: 100%;
      position: relative;
      z-index: 10;

      h2 {
        line-height: 1.1;
        position: relative;
        z-index: 2;
      }

      p {
        color: $neutral_80;
        font-weight: 500;
        position: relative;
        z-index: 2;

        strong {
          color: $brand-black;
        }
      }

      .img-wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 90%;
      }
    }

    .seller-tab-right-box {
      height: calc(100vh - 82px);
      padding: 80px;
      overflow-y: auto;

      .details {
        p {
          color: $neutral_80;
          font-weight: 500;
        }

        .form-group {
          label {
            font-size: 12px;
            margin-bottom: 0;
          }

          .form-control {
            height: 48px;
          }
        }
      }

      .footer-details {
        background-color: $toggle-disable;
        position: fixed;
        left: 25%;
        right: 0;
        bottom: 0;
        padding: 12px 25px;
        z-index: 5;
        display: flex;
        justify-content: flex-end;

        .btn-white {
          &:hover {
            background-color: $brand_white;
          }
        }

        .btn-disabled, .btn-action {
          width: fit-content;
          padding: 12px 25px;
        }
      }

    }
  }

}

.custom-datepicker {
  box-shadow: none;
  background: $brand-transparent;

  .bs-datepicker-container {
    padding-top: 5px;

    .bs-calendar-container {

      .bs-media-container {
        .bs-datepicker-head {
          background-color: $brand-primary;
          border-radius: 16px 16px 0 0;

          button {
            &:not([disabled]):hover {
              background-color: $brand-secondary;
              color: $brand-white;
            }
          }
        }

        .bs-datepicker-body {
          background-color: $brand-white;
          border-radius: 0 0 16px 16px;

          table {
            th {
              color: $primary_10
            }

            td {
              color: $primary_dark;

              &.is-other-month {
                color: $neutral_20;
              }

              &.is-highlighted {
                &:not(.disabled):not(.selected) {
                  span {
                    background-color: $secondary_80;
                    color: $brand_white;
                  }
                }
              }

              span {
                &.is-highlighted {
                  &:not(.disabled):not(.selected) {
                    background-color: $secondary_80;
                    color: $brand_white;
                  }
                }
              }

              &.selected {
                background-color: $brand-secondary;

                span {
                  background-color: $brand-secondary;
                }
              }

              span {
                &.selected {
                  background-color: $brand-secondary;
                }
              }

              &.disabled {
                cursor: not-allowed;

                &:not(.is-other-month) {
                  span {
                    color: $primary_20;
                  }
                }
              }

              span {
                &.disabled {
                  cursor: not-allowed;

                  &:not(.is-other-month) {
                    color: $primary_20;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.upload-progress {
  .progress-item {
    p {
      margin-bottom: 0;
    }

    .progress {
      background-color: $secondary_40;

      .progress-bar {
        background-color: $brand-primary
      }
    }
  }
}

/*====================
header Component Css
====================*/
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $brand-white;
  box-shadow: 0 0 8px rgba($neutral_20, 0.20);
  z-index: 10;
  padding: 12px 15px;

  .header-logo {
    width: 235px;
  }

  .header-right-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-header {
      .search-input {
        width: 275px;
        padding-left: 35px;
      }
    }

    .right-header {
      display: flex;
      align-items: center;

      .user-profile {
        overflow: hidden;
        border-radius: 50px;

        span {
          min-height: 42px;
          min-width: 42px;
          display: flex;
          align-items: center;
          background-color: $brand-primary;
          color: $brand-white;
          padding: 5px 10px;
          border-radius: 25px;
          cursor: pointer;
          justify-content: center;
        }

        &.no-image {
          overflow: unset;
        }
      }
    }
  }
}

/*====================
Menu Component Css
====================*/
.side-menu {
  position: fixed;
  height: 100vh;
  left: 0;
  width: 235px;
  background-color: $toggle-disable;
  z-index: 5;
  padding: 88px 10px 15px;
  overflow-y: auto;

  ul {
    li {
      list-style: none;
      padding: 10px;

      a {
        font-weight: 700;
        line-height: 1.2;
        color: $neutral_40;
        padding: 15px;
        border-radius: 16px;
        display: flex;
        text-transform: capitalize;
        align-items: center;
        transition: all 0.3s ease-in-out;
        text-decoration: none;

        .dark-icon {
          display: none;
        }

      }

      &:hover,
      &.active {
        a {
          background: $primary_10;
          color: $brand-primary;
          text-decoration: none;

          .dark-icon {
            display: inline-block;
          }

          .light-icon {
            display: none;
          }
        }
      }
    }
  }
}

/*====================
Dashboard Component Css
====================*/
.dashboard {
  .store-pending {
    width: 100%;
    background-color: $toggle-disable;
    padding: 10px 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .store-message {
      img {
        margin-right: 11px;
      }
    }

    .count {
      color: $secondary_80;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

/*====================
Custom table css
====================*/
.custom-table {
  .dataTables_wrapper {
    .dataTables_filter {
      position: relative;
      width: 20%;
      margin-bottom: 1.5rem;

      label {
        width: 100%;

        input {
          width: 100%;
          background-color: $toggle-disable;
          border-radius: 16px;
          border: 1px solid $neutral_20;
          color: $brand-black;
          padding: 0.375rem 0.75rem 0.375rem 35px;
          height: 48px;

          &::placeholder {
            color: $neutral_40;
          }

          &:focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
          }
        }
      }

      &:after {
        color: $neutral_40;
        position: absolute;
        font-family: "Font Awesome 6 Free";
        font-weight: 600;
        font-size: 14px;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        transition: all 0.3s;
        content: '\f002';
      }
    }

    .dataTables_scroll {
      background-color: $toggle-disable;
      padding: 14px 26px;
      border-radius: 16px;
      overflow: hidden;

      .dataTables_scrollHead {
        background-color: $brand-white;
        border: none;
        margin: 7px 0;
        border-radius: 16px;

        .sb-table {
          border: none !important;
          border-image: none;

          thead {
            td {
              border: none;
              padding-left: 12px;
              padding-right: 12px;

              p {
                width: fit-content;
                color: $neutral_80;
              }

              &.sorting {
                background-image: none !important;
                &:before,
                &:after {
                  display: none;
                }

                p {
                  &:before,
                  &:after {
                    color: $neutral_40;
                    position: absolute;
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                    font-size: 9px;
                    right: -18px;
                    transform: translateY(-50%) rotateY(0);
                    transition: all 0.3s;
                  }

                  &:before {
                    content: '\f106';
                    top: 30%;
                  }

                  &:after {
                    content: '\f107';
                    top: 65%;
                  }
                }

                &.sorting_desc {
                  p {
                    &:before {
                      display: none;
                    }

                    &:after {
                      display: block;
                    }
                  }
                }

                &.sorting_asc {
                  p {
                    &:before {
                      display: block;
                    }

                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .dataTables_scrollBody {
        position: unset !important;
        border: 0;
        margin-top: -10px;

        .sb-table {
          border-collapse: separate;
          border-spacing: 0 15px;

          //thead {
          //  display: none;
          //}

          tbody {
            tr {
              td {
                border-radius: 16px 0 0 16px;

                + td {
                  border-radius: 0;
                }

                &:last-child {
                  border-radius: 0 16px 16px 0;
                }

                &:only-child {
                  border-radius: 16px;
                  text-align: center;
                }

                background-color: $brand-white;
                border: 0;
                padding: 12px;

                .store-content-details {
                  display: flex;
                  align-items: center;

                  img {
                    width: 32px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }

      @media (max-width: 576px) {
        padding: 0;
      }
    }

    .dataTables_paginate {
      padding-top: 0;
      overflow: hidden;
      margin-top: 15px;
      border-radius: 5px;

      .paginate_button {
        color: rgba($brand_black, 0.5) !important;
        font-weight: 500;
        transition: all 0.3s;
        border: 1px solid rgba($brand-black, 0.1);
        border-right: 0;
        margin: 0;
        border-radius: 0;
        padding: 0.3rem 0.75rem;

        &.first {
          border-radius: 5px 0 0 5px;
        }

        &.last {
          border-radius: 0 5px 5px 0;
          border: 1px solid rgba($brand-black, 0.1);

          &:hover {
            border-right: 1px solid $primary_10;
          }
        }

        &.current {
          color: $brand-white !important;
          border: 1px solid $brand-primary;
          background: $brand_primary;

          &:hover {
            background: $brand_primary;
            color: $brand-white !important;
            border: 1px solid $brand-primary;
          }
        }

        &:hover {
          background: $primary_10;
          color: $brand-primary !important;
          border: 1px solid $primary_10;
          border-right: 0;
        }

        &.disabled {
          background: $toggle-disable;
          color: $primary_20 !important;
          cursor: no-drop;
        }
      }

      .ellipsis {
        border-top: 1px solid rgba($brand-black, 0.1);
        border-bottom: 1px solid rgba($brand-black, 0.1);
        border-left: 1px solid rgba($brand-black, 0.1);
        padding-top: 6px;
        padding-bottom: 7px;
      }
    }

    .dataTables_processing {
      z-index: 1;
      margin-top: 0;
    }
  }

  &.finances-table {
    padding-bottom: 50px;

    .dataTables_wrapper {
      .dataTables_scroll {
        .dataTables_scrollBody {
          overflow: unset !important;

          .sb-table {
            .group {
              background-color: $brand-transparent;
              box-shadow: none;

              td {
                background-color: $brand-transparent;
                text-align: left;
                font-weight: 700;
                font-size: 16px;
                padding-bottom: 0;
              }
            }

            tr {
              box-shadow: 0 0 8px 0 rgba($brand-black, 0.01);
              border-radius: 16px;

              td {
                font-weight: 500;

                .debitAmount {
                  color: $error;
                }
              }
            }
          }
        }
      }
    }
    &.seller-finances-table {
      .dataTables_wrapper {
        .dataTables_scroll {
          .dataTables_scrollBody {
            overflow: auto !important;
          }
        }
      }
    }
  }
}

.vendor-profile-not-verified {
  .section {
    border-radius: 8px;
    background-color: $toggle-disable;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: $brand-secondary;

    .send-verification {
      color: $brand-secondary;
      background: transparent;
      border: 0;
      text-decoration: underline;
    }
  }
}


.products-list {
  .products-list-wrapper {
    .dataTables_scrollBody {
      .sb-table {
        tbody {
          tr {
            td {
              .products-list {
                padding: 16px;
                border-radius: 8px;
                background: $toggle-disable;
                display: flex;

                .img-wrapper {
                  max-width: 56px;
                  max-height: 72px;
                  border-radius: 8px;
                  overflow: hidden;

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.orders-list {
  .custom-table {
    .dataTables_scroll {
      @media (max-width: 767px) {
        background-color: $brand-transparent;
      }

      //.dataTables_scrollHead {
      //  display: none;
      //}

      .dataTables_scrollBody {
        table {
          tbody {
            tr {
              td {
                padding: 0;

                &:only-child {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.odoo-model {
  max-width: 700px;

  .modal-content {
    padding: 0 40px;

    .contact {
      padding: 0;

      .contact-form {
        padding-top: 30px !important;
      }
    }
  }
}
.finance-table{
  .custom-table{
      .dataTables_scroll{
        overflow: visible;
      }
  }
}

@import "responsive";
